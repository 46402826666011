<template>
  <el-container class="vue-container">
    <el-aside class="vue-sidebar" :style="{width: this.isActive ? '60px' : '150px'}">
      <side-bar :is-active="isActive"></side-bar>
    </el-aside>
    <el-container>
      <el-header class="vue-header" style="background-color: #545c64">
        <header-vue :display-name="displayName" @changeActive="changeActive"></header-vue>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer style="background-color: #545c64">
        <a href="https://beian.miit.gov.cn/" style="color: white">渝ICP备2023017639号-2</a>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>

import SideBar from '@/views/fragments/sideBar'
import HeaderVue from '@/views/fragments/header'
import Storage from '@/utils/storage'

export default {
  name: 'layOut',
  data () {
    return {
      displayName: '',
      isActive: false
    }
  },
  components: {
    SideBar,
    HeaderVue
  },
  created () {
    this.displayName = Storage.getItemForLs('user').userName
  },
  methods: {
    changeActive () {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.vue-container {
  /* font-family: "monaco";
  font-size: 13px; */
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.vue-header {
  padding: 0;
  z-index: 1000;
}
.vue-sidebar {
  height: 100%;
  transition: width 0.5s;
}
</style>
